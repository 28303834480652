import '../style/App.sass';
import Main from './views/Main';
import Three from './views/Three';
import Fallback from './components/Fallback';
import { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Suspense fallback={Fallback}>
      <div className="App">
        <Router>
          <Routes>
            {/* <Route path="/" component={Three}/> */}
            <Route path="/three/" element={<Three/>}/>
            <Route path="/" element={<Main/>}/>
          </Routes>
        </Router>
      </div>
    </Suspense>
  );
}

export default App;