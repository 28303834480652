import '../../style/components/tiles.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapPin } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as AngularIcon } from '../../assets/angular-icon.svg';
import { ReactComponent as ReactIcon } from '../../assets/react-icon.svg';
import { ReactComponent as VueIcon } from '../../assets/vue-icon.svg';
import { ReactComponent as NodeIcon } from '../../assets/nodejs-icon.svg';
import { ReactComponent as CSSIcon } from '../../assets/css3-icon.svg';
import { ReactComponent as SASSIcon } from '../../assets/sass-icon.svg';
import { ReactComponent as UnityIcon } from '../../assets/unity-icon.svg';
import { ReactComponent as APSIcon } from '../../assets/aps-icon.svg';
import { ReactComponent as AIIcon } from '../../assets/ai-icon.svg';
import { ReactComponent as AAEIcon } from '../../assets/aae-icon.svg';
import { ReactComponent as AMEIcon } from '../../assets/ame-icon.svg';
import { ReactComponent as OBSIcon } from '../../assets/obs-icon.svg';
import { ReactComponent as LinkedInLogo } from '../../assets/linkedin-logo.svg';
import { ReactComponent as GitHubIcon } from '../../assets/github-icon.svg';
import { ReactComponent as GitLabIcon } from '../../assets/gitlab-icon.svg';
import { ReactComponent as WordPressIcon } from '../../assets/wordpress-icon.svg';
import { ReactComponent as GambioIcon } from '../../assets/gambio-icon.svg';

export function Title(props){
    const tiles = [
        <div className='title-tile'>
            <div className='title-tile-header'>
                Maximilian Buchholz
            </div>
            <div className='title-tile-line'>
                <span className="material-icons md-36">location_on</span>
                &nbsp; Dresden, Germany | Web Engineer
            </div>
        </div>
    ];

    return { "tiles": tiles,
        "style": "style-1"
    };
}

export function Skills(props){
    const tiles = [
        <div className='title-tile'>
            <div className='headline'>
                Skills
            </div>
        </div>,

        <div className='content-tile'>
            <div className='content-tile-main'>
                <div className='content-tile-header'>
                    Frontend Developement
                </div>
                <div className='content-tile-body'>
                    {
`{
    types: [
        'HTML',
        'JavaScript',
        'TypeScript',
        'CSS',
        'SASS'
    ],
    ext: [
        'Angular',
        'React',
        'Vue'
    ]
}`
// `<html>
//     <head>
//         <meta
//             name="HTML Skills"
//             content="Preview of some html code"
//         />
//     </head>
//     <body>
//         <-- A lot of magic, but mainly javascript -->
//         <root/>
//     </body>
// </html>`
                    }
                </div>
            </div>
            <div className='content-tile-sidebar'>
                <a href="https://angular.io/" rel="noreferrer" target="_blank">
                    <div className="tiles-icon-frame">
                        <AngularIcon className='tiles-icon'/>
                    </div>
                </a>
                <a href="https://reactjs.org/" rel="noreferrer" target="_blank">
                    <div className="tiles-icon-frame" >
                        <ReactIcon className='tiles-icon'/>
                    </div>
                </a>
                <a href="https://vuejs.org/" rel="noreferrer" target="_blank">
                    <div className="tiles-icon-frame">
                        <VueIcon className='tiles-icon' style={{ marginTop: "10px", padding: "15px" }}/>
                    </div>
                </a>
            </div>
        </div>,

        <div className='content-tile'>
            <div className='content-tile-main'>
                <div className='content-tile-header'>
                    Backend Developement
                </div>
                <div className='content-tile-body'>
                    {
`{
    types: [
        'JavaScript',
        'TypeScript',
        '(PHP)',
        'SQL'
    ],
    ext: [
        'Node.js',
        '(Symfony)',
        'MariaDB'
    ]
}`
// `export default function exampleFunction(params) {
//     var example = 0;
//     for (param of params) {
//         example += param;
//     };

//     return example;
// };`
                    }
                </div>
            </div>
            <div className='content-tile-sidebar' style={{ color: 'white' }}>
                <a href="https://nodejs.org/" rel="noreferrer" target="_blank">
                    <div className='tiles-icon-frame l'>
                        <NodeIcon className="tiles-icon l"/>
                    </div>
                </a>
            </div>
        </div>,

        <div className='content-tile'>
            <div className='content-tile-main'>
                <div className='content-tile-header'>
                    Design & Media Creation
                </div>
                <div className='content-tile-body'>
                    {
`[
    'Adobe Photoshop',
    'Adobe Illustrator',
    'Adobe After Effects',
    'OBS Studio',
]
`
                    }
                </div>
            </div>
            <div className='content-tile-sidebar'>
                <a href="https://www.adobe.com/de/products/photoshop.html" rel="noreferrer" target="_blank">
                    <div className="tiles-icon-frame">
                        <APSIcon className='tiles-icon' style={{ padding: '15px' }}/>
                    </div>
                </a>
                <a href="https://www.adobe.com/de/products/illustrator.html" rel="noreferrer" target="_blank">
                    <div className="tiles-icon-frame" >
                        <AIIcon className='tiles-icon' style={{ padding: '15px' }}/>
                    </div>
                </a>
                <a href="https://www.adobe.com/de/products/aftereffects.html" rel="noreferrer" target="_blank">
                    <div className="tiles-icon-frame">
                        <AAEIcon className='tiles-icon' style={{ padding: '15px' }}/>
                    </div>
                </a>
            </div>
        </div>,

        <div className='content-tile'>
        <div className='content-tile-main'>
            <div className='content-tile-header'>
                Other things
            </div>
            <div className='content-tile-body'>
                {
`[
        'WordPress',
        'Gambio'
]

`
                }
                <a href="https://blog.buchholz.ws/" target="_blank" rel="noreferrer">blog.buchholz.ws</a>
                <a href="https://www.glasgravur-strausberg.de/" target="_blank" rel="noreferrer">glasgravur-strausberg.de</a>
                <a href="/three">three.js playground</a>
            </div>
        </div>
        <div className='content-tile-sidebar'>
            <a href="https://wordpress.com/" rel="noreferrer" target="_blank">
                <div className="tiles-icon-frame">
                    <WordPressIcon className='tiles-icon' style={{ padding: '10px' }}/>
                </div>
            </a>
            <a href="https://www.gambio.de/" rel="noreferrer" target="_blank">
                <div className="tiles-icon-frame">
                    <GambioIcon className='tiles-icon' style={{ padding: '10px' }}/>
                </div>
            </a>
        </div>
        </div>
    ];

    return { "tiles": tiles,
        "style": "style-2"
    };
}

export function Portfolio(props){
    const tiles = [
        <div className='title-tile'>
            <div className='headline'>
                Portfolio            
            </div>
        </div>,

        <div className='content-tile'>
            <div className='content-tile-main'>
                <div className='content-tile-header'>
                    EsCaPEr (<a href="https://m-buchholz.github.io/EsCaPEr/" target="_blank" rel="noreferrer">Link</a>)
                </div>
                <div className='content-tile-body'>
                    {
`EsCaPEr ist ein Lernspiel, welches an der TU Dresden, im Rahmen einer Gruppenarbeit entwickelt wurde.
Mein Anteil ist die Einrichtung des Projektes mit grundlegenden Szenen und Navigation zwischen den Szenen. Ebenso das abschließende Minispiel und einige interaktive Elemente, Animationen und auch Zeichnungen mit Photoshop und Illustrator.`
                    }
                </div>
            </div>
            <div className='content-tile-sidebar'>
                <UnityIcon fill='white'/>
            </div>
        </div>,

        <div className='content-tile'>
            <div className='content-tile-main'>
                <div className='content-tile-header'>
                    "Stundenplan bauen" (<a href="https://www.youtube.com/watch?v=-7dKjIh3VkI" target="_blank" rel="noreferrer">Link</a>)
                </div>
                <div className='content-tile-body'>
                    {
`Der Erklärfilm "Stundenplan bauen" ist ein Animationsfilm, der an der TU Dresden erstellt wurde. In einer Gruppe aus 2 Personen haben wir das Projekt geplant und unter Beachtung des Styleguides sämtliche Grafiken in Adobe Illustrator gezeichnet. Diese Grafiken haben wir in Adobe After Effects animiert, mit externen Sounds/Musik versehen und mit Adobe Media Encoder gerendert.`
                    }
                </div>
            </div>
            <div className='content-tile-sidebar m3-icons'>
                <AIIcon/>
                <AAEIcon/>
                <AMEIcon/>
            </div>
        </div>
    ];

    return { "tiles": tiles,
        "style": "style-3"
    };
}

export function Social(props){
    const tiles = [
        <div className='title-tile'>
            <div className='headline'>
                Social
            </div>
        </div>,

        <div className='content-tile'>
            <div className='social-link'>
                <a href="https://www.linkedin.com/in/maximilian-buchholz/" target="_blank" rel="noreferrer"><LinkedInLogo/></a>
            </div>
        </div>,

        <div className='content-tile'>
            <div className='social-link'>
                <a href="https://github.com/m-buchholz" target="_blank" rel="noreferrer"><GitHubIcon/></a>
            </div>
        </div>,

        <div className='content-tile'>
            <div className='social-link'>
                <a href="https://gitlab.com/m-buchholz" target="_blank" rel="noreferrer"><GitLabIcon/></a>
            </div>
        </div>
    ];

    return { "tiles": tiles,
        "style": "style-4"
    };
}

export function Contact(props){
    const tiles = [
        <div className='title-tile'>
            <div className='contact-image'>
                <img src='../img/banner.png' alt=''></img>
            </div>
            <div className="content-tile-header" style={{ textAlign: "center", color: "darkgrey" }}>
                {/* Impressum {`>`} */}
            </div>
        </div>

//         <div className='content-tile' onClick={() => props.scroll(0)}>
//             <div className='content-tile-main'>
//                 <div className='content-tile-header'>
                    
//                 </div>
//                 <div className='content-tile-body'>
//                     {
// `Maximilian Buchholz
// Karl-Marx-Straße 7
// 15344 Strausberg

// kontakt@buchholz.ws
// `
//                     }
//                 </div>
//             </div>
//             <div className='content-tile-sidebar'>
//             </div>
//         </div>
    ];

    return { "tiles": tiles,
        "style": "style-5"
    };
}