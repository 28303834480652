import '../../style/views/main.sass';
import TileGallery from '../components/TileGallery';
import { Title, Skills, Portfolio, Social, Contact } from '../components/Tiles';

export default function Main(props) {
    return (
        <div className="body-wrapper">
            <TileGallery tiles={Title()}></TileGallery>
            <TileGallery tiles={Skills()}></TileGallery>
            <TileGallery tiles={Portfolio()}></TileGallery>
            <TileGallery tiles={Social()}></TileGallery>
            <TileGallery tiles={Contact()}></TileGallery>
        </div>
    );
}