import '../../style/components/tile.sass';

export default function Tile(props) {
    if (props.details) {
        return(
            <div className='tile-wrapper'>
                <div className={`tile-content ${props.style}`}>
                    {props.content}
                </div>
            </div>
        );
    } else {
        return(
            <div className='tile-wrapper'>
                <div className={`tile-content ${props.style}`}>
                    {props.content}
                </div>
            </div>
        );
    }
}