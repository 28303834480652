import '../../style/components/tilegallery.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { useRef } from 'react';
import Tile from '../components/Tile';

export default function TileGallery(props) {
    const ref = useRef(null);

    const scroll = (dir) => {
        if (dir === 0) {
            ref.current.scrollLeft -= ref.current.offsetWidth;
        } else {
            ref.current.scrollLeft += ref.current.offsetWidth;
        };
    };

    return(
        <div className='gallery-wrapper'>
            <div className='gallery-content'  ref={ref}>
                {props.tiles['tiles'].map((tile, i) => (
                    <div className='gallery-element' key={i}>
                        {props.tiles['tiles'].length > 1 && i > 0 &&
                            <div className='side-icon left'>
                                <FontAwesomeIcon onClick={() => scroll(0)} className='icon' icon={faAngleLeft}/>
                            </div>
                        }

                        <Tile style={props.tiles['style']} content={tile}></Tile>

                        {props.tiles['tiles'].length > 1 && i < props.tiles['tiles'].length-1 &&
                            <div className='side-icon right'>
                                <FontAwesomeIcon onClick={() => scroll(1)} className='icon' icon={faAngleRight}/>
                            </div>
                        }
                    </div>
                ))}
            </div>
        </div>
    );
}