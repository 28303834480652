import '../../style/views/three.sass';
import * as THREE from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { useEffect, useRef } from 'react';

export default function Main(props) {
    const mountRef = useRef(null);

    useEffect(() => {
        var scene = new THREE.Scene();
        scene.background = new THREE.Color(0xffffff);
        var camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 1, 1000 );
        camera.translateOnAxis(new THREE.Vector3(0, 0, 8), 1);
        // camera.translateOnAxis(new THREE.Vector3(1, 1, 5), 1);
        // camera.rotateOnAxis(new THREE.Vector3(0, 0, 0), 1);
        var renderer = new THREE.WebGLRenderer();
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = THREE.PCFSoftShadowMap;

        var cube = new THREE.Mesh(
            new THREE.BoxGeometry(3, 3, 3),
            new THREE.MeshStandardMaterial({
                color: 0xffffff
            })
        );
        cube.castShadow = true;
        // cube.translateY(0);
        scene.add(cube);

        var ground = new THREE.Mesh(
            new THREE.PlaneGeometry(10, 5),
            new THREE.MeshStandardMaterial({
                color: 0xffffff,
                side: THREE.DoubleSide
            })
        );
        ground.receiveShadow = true;
        ground.translateY(-3);
        ground.rotateOnAxis(new THREE.Vector3(0.8, 0, 0), 90);
        scene.add(ground);

        var directionalLight = new THREE.DirectionalLight( 0xffffff, 1 );
        directionalLight.position.set(0, 5, 0);
        directionalLight.castShadow = true;
        scene.add( directionalLight );

        renderer.setSize( window.innerWidth, window.innerHeight );
        mountRef.current.appendChild( renderer.domElement );

        // https://stackoverflow.com/questions/13055214/mouse-canvas-x-y-to-three-js-world-x-y-z
        var getThreeMousePosition = function(event) {
            var vec = new THREE.Vector3(); // create once and reuse
            var pos = new THREE.Vector3(); // create once and reuse
            vec.set(
                ( event.clientX / window.innerWidth ) * 2 - 1,
                - ( event.clientY / window.innerHeight ) * 2 + 1,
                0.5 );
            vec.unproject( camera );
            vec.sub( camera.position ).normalize();
            var distance = - camera.position.z / vec.z;
            return pos.copy( camera.position ).add( vec.multiplyScalar( distance ) );
        }
        
        // animate objects
        var animate = function () {
            requestAnimationFrame( animate );
            // cube.rotation.x = getThreeMousePosition().x;
            // cube.rotation.x += 0.01;
            // cube.rotation.y += 0.01;
            // cube.rotation.z += 0.01;
            renderer.render( scene, camera );
        };

        // animate on mouse
        var animateOnMouse = function (event) {
            var pos = getThreeMousePosition(event);
            cube.rotation.x = -pos.y * 0.05;
            cube.rotation.y = pos.x * 0.05;
        };

        // change sizes on window changes
        let onWindowResize = function () {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize( window.innerWidth, window.innerHeight );
        };
        window.addEventListener("resize", onWindowResize, false);
        window.addEventListener('mousemove', animateOnMouse);

        animate();

        return() => mountRef.current.removeChild( renderer.domElement);
    });

    return (
        <div ref={mountRef}/>
    );
}

function LoadModel(path) {
    const loader = new GLTFLoader();

    loader.load(path, function (gltf) {
    // loader.load( car , function (gltf) {
        var model = gltf.scene;
        // model.scale.set(200,200,200);
        console.log('model: ', model);
        return model;
    }, undefined, function (error) {
        console.log(error);
        return null;
    });
}