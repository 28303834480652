import '../../style/components/fallback.sass';

export default function Fallback(props){
    return(
      <div className="fallback">
        <div className="fallback-content">
          <div className="fallback-loader"></div>
          <img className="fallback-img" src="/logo512.png" alt="Logo"></img>
        </div>
      </div>
    );
}